import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Our CEO is a finalist for the Business Leader of the Year 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Our CEO is a finalist for the Business Leader of the Year</h1>
        
          <StaticImage
          layout="constrained"
          src="images/Leader-purple-cow.png"
          alt="Our CEO is a finalist for the Business Leader of the Year"
          /> 
            <p>I'm thrilled to share that alongside four other outstanding individuals I've been named a finalist for the "Business Leader of the Year Award" by the Halifax Chamber of Commerce. It's truly an honor for Purple Cow and a testament to the impact everyone on our team is making in our community. But here's the kicker: with utmost humility, "We are just getting started."</p>
            <p>At this very moment, I find myself in Austin on a personal development trip, absorbing invaluable lessons from accomplished business leaders. The insights are pouring in – from refining our quarterly meetings to mastering hiring techniques and magnifying our brand story. The is wisdom coming in so thick and fast that I'm scrambling to write it all down.</p>  
            <p>My point is win or lose being down here in Austin underscores the realization: there's always more to learn. So when I say "I'm just getting started," it's because I am only a fraction of what I want to become. In saying that, I want to say thank you to all the herd members for all your support and let's keep pushing the boundaries together!</p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
